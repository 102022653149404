
body {
  font-family: alwyn-new-rounded-web, sans-serif;
font-weight: 400;
font-style: normal;
  color:#222222;
}

body{
  background-color: #fbfbfb;
}
.logo{
  width: 18rem;
  padding: 20px;
  
  
  }
.minilogo{
  margin-left:auto;
  margin-right:auto;
  display: block;
}
.button_link{
  text-decoration: none;
  color: #39ac7a;
  }
.main_col{
  background-color:#fff;
padding-top:5%;
padding-bottom:5%;
border-radius: 20px;
box-shadow:  5px 10px rgba(0,0,0,0.1),             
-5px -5px 10px #fff ;
  
-moz-box-shadow:
5px 5px 10px rgba(0,0,0,0.1),             
-5px -5px 10px #fff ;

box-shadow:
5px 5px 10px rgba(0,0,0,0.1),             
-5px -5px 10px #fff ;
}

p, .zusatz{
  text-align: center;
}


i.icon{
  font-size:3em;
  color:#39ac7a;
  text-align: left;
  
}

.opacity{
  opacity: 0;
}

.contact{
  width:20%;
  margin-left:auto;
  margin-right:10%;
}
